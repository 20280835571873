import { ArrowRight01Icon, DashboardSquareSettingIcon } from "hugeicons-react";
import React from "react";
import { Link } from "react-router-dom";

const CantModify = () => {
  const userFirstName = JSON.parse(localStorage.getItem("userData")).first_name;
  return (
    <div className="no-resources-container">
      <div className="no-resources-wrapper">
        <h2> Hi, {userFirstName}</h2>
        <h1>Incident Closed</h1>
        <p>
          Closed incidents cannot be edited or modified, below are some actions
          you can perform.
        </p>
        <Link className="overview-btn" to={"/"}>
          <DashboardSquareSettingIcon
            size={20}
            color={"#ffffff"}
            variant={"stroke"}
            className="overview-icon"
          />

          <span>Go to overview</span>
          <ArrowRight01Icon
            size={20}
            color={"#ffffff"}
            variant={"stroke"}
            className="right-arrow"
          />
        </Link>
      </div>
    </div>
  );
};

export default CantModify;
