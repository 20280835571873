import React, { useEffect, useState } from "react";
import {
  CancelCircleIcon,
  ArrowRight02Icon,
  CheckmarkCircle02Icon,
  CircleIcon,
  Loading02Icon,
  PencilEdit01Icon,
  PencilEdit02Icon,
} from "hugeicons-react";
import { TINYEMCE_API_KEY } from "../../../api";
import { Editor } from "@tinymce/tinymce-react";
import toast, { LoaderIcon } from "react-hot-toast";
import api, { API_URL } from "../../../api";
import mediaAPI from "../../../mediaApi";
import FormatDate from "../../general/formatDateTime";
import DateFormatter from "../../../pages/dashboard/incidents/dateFormatter";
import SearchableSelect from "../../form/SeachableSelect";
import { Link } from "react-router-dom";
import { usePermission } from "../../../contexts/permissionsContext";
const SendToDepartmentForm = ({
  incident,
  incidentId,
  apiLink,
  closeForm,
  sendTo,
  complaint,
  hasDocuments,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const permission = usePermission();
  const [departments, setDepartments] = useState([]);
  const [departmentMembers, setDepartmentMembers] = useState([]);
  const [unfilteredMembers, setUnfilteredMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [selectedDepartmentIndex, setSelectedDepartmentIndex] = useState("");
  const [comment, setComment] = useState("");
  const [departmentNotified, setDepartmentNotified] = useState("");
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // const [file, setFile] = useState("")
  const [gettingDepartments, setGettingDepartments] = useState(false);
  const [gettingDepartmentMembers, setGettingDepartmentMembers] =
    useState(false);
  const [headOfDepartment, setHeadOfDepartment] = useState(null);

  const fetchDepartmentMembers = async (departmentId) => {
    try {
      const response = await api.get(
        `${API_URL}/departments/${departmentId}/members/`
      );
      if (response.status === 200) {
        setUnfilteredMembers(response.data.members);
        setDepartmentMembers(
          response.data.members.filter((user) => user.is_head === false)
        );
        setHeadOfDepartment(
          response.data.members.filter((user) => user.is_head === true)[0]
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGettingDepartmentMembers(false);
    }
  };

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
    fetchDepartmentMembers(department.value);
    setSelectedDepartmentId(department.value);
    setGettingDepartmentMembers(true);
  };
  const handleSelectedMembers = (user_id) => {
    setSelectedMembers((prevSelected) => {
      // Check if the user_id is already in the selected array
      const isAlreadySelected = prevSelected.some(
        (user) => user.user_id === user_id
      );

      if (isAlreadySelected) {
        return prevSelected.filter((user) => user.user_id !== user_id);
      } else {
        return [...prevSelected, { user_id }];
      }
    });
  };

  const handleSendToDepartment = async () => {
    setIsLoading(true);

    console.log(headOfDepartment);
    const departmentHead = [
      {
        user_id: headOfDepartment.id,
      },
    ];

    if (!comment && !complaint) {
      window.customToast.error("Please enter a comment");
      setIsLoading(false);
      return;
    }

    try {
      const postData = {
        department: selectedDepartmentId,
        assignees:
          selectedMembers.length > 0
            ? [...selectedMembers, departmentHead[0]]
            : departmentHead,
        comment: comment,
      };

      const endPoint = complaint
        ? apiLink
        : `incidents/${apiLink}/${incidentId}/${sendTo}`;
      const sendToDepartmentResponse = await api.put(
        `${API_URL}/${endPoint}/`,
        postData
      );

      if (sendToDepartmentResponse.status === 200) {
        window.customToast.success("Sent to department");
        if (complaint) {
          closeForm();
          return;
        }
        const saveReviewResponse = await api.post(
          `${API_URL}/incidents/${apiLink}/${incidentId}/reviews/new/`,
          { content: comment }
        );

        if (
          saveReviewResponse.status === 201 ||
          saveReviewResponse.status === 200
        ) {
          window.customToast.success(selectedDepartment.label + " is notified");
          setDepartmentNotified(true);
          setTimeout(() => {
            closeForm();
          }, 2000);
          console.log(saveReviewResponse.data);
        } else {
          window.customToast.error(
            "Error saving review. Go to the reviews tab and add your comment",
            saveReviewResponse
          );
        }
      } else {
        window.customToast.error("Error sending to department");
        return;
      }
    } catch (error) {
      console.error("Error in handleSendToDepartment:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);

      const response = await mediaAPI.post(
        `/incidents/${apiLink}/${incidentId}/documents/new/`,
        formData
      );

      if (response.status === 200) {
        setUploadingDocuments(false);
        window.customToast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
        // setIncident(response.data.incident);
      }
    } catch (error) {
      window.customToast.error(error?.response?.data?.error);
      setUploadingDocuments(false);
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchDepartments = async () => {
      setGettingDepartments(true);
      try {
        const response = await api.get(
          `/facilities/${incident.report_facility.id}/departments/`
        );
        if (response.status === 200) {
          setDepartments(response.data.departments);
          setGettingDepartments(false);
        }
      } catch (error) {
        console.log(error);
        if (!incident.report_facility) {
          window.customToast.error("This incident does not have a facility");
        }
      } finally {
        setGettingDepartments(false);
      }
    };
    fetchDepartments();
  }, []);

  const checkHeadOfDepartment = () => {
    // Check if the head of department is present in the list of members
    console.log(unfilteredMembers);
    const hasHead =
      unfilteredMembers &&
      unfilteredMembers.some((member) => member.is_head === true);
    return hasHead;
  };
  return (
    <div className="incident-popup">
      <div className="popup-content send-to-department">
        <div className="popup-header">
          <h3>Send to department</h3>

          <div onClick={closeForm} className="icon">
            <CancelCircleIcon size={24} variant={"stroke"} />
          </div>
        </div>
        {!checkHeadOfDepartment() &&
          permission &&
          !permission.includes("Super User") && (
            <div className="error-message">
              <p className="message-icon">
                Please contact the system administrator to add a head a
                department for {selectedDepartment.label}
              </p>
            </div>
          )}
        <div className="head-of-department"></div>
        {selectedDepartment === "" ? (
          <>
            <p>Select department</p>
            {/* <div className="search-field">
                                <input type="search" name="searchDepartment" id="" placeholder='Search department' />
                            </div> */}
            {/* <div className="available-departments">
                                <p>Available departments</p>
                                <small>16</small>
                            </div> */}
          </>
        ) : (
          ""
        )}

        {gettingDepartments ? (
          <div className="loading">
            <Loading02Icon
              className="loading-icon"
              size={20}
              variant={"stroke"}
            />
            <p>Loading departments...</p>
          </div>
        ) : (
          departments &&
          departments.length > 0 && (
            <SearchableSelect
              options={
                departments &&
                departments.map((dep) => ({ label: dep.name, value: dep.id }))
              }
              value={selectedDepartment}
              onChange={handleSelectDepartment}
              placeholder={selectedDepartment.label || "Select a department"}
            />
          )
        )}
        {selectedDepartment && (
          <>
            <p>
              Head of department,{" "}
              <strong>
                {headOfDepartment?.first_name} {headOfDepartment?.last_name}
              </strong>{" "}
              with email <strong>{headOfDepartment?.email}</strong> will be
              notified
            </p>
            {gettingDepartmentMembers ? (
              <div className="loading">
                <Loading02Icon
                  className="loading-icon"
                  size={20}
                  variant={"stroke"}
                />
                <p>Loading department members...</p>
              </div>
            ) : (
              <div
                className="departments"
                style={{ height: "100%", maxHeight: "200px", overflow: "auto" }}
              >
                {
                  // check if selected department has any members that have is_head===true
                  checkHeadOfDepartment() ? (
                    <>
                      Would you like to send this incident to anyone else from
                      this department ? If so, select them.
                      {departmentMembers && departmentMembers.length > 0 ? (
                        <div className="form-choices">
                          {departmentMembers.map((departmentMember, index) => (
                            <div
                              className={`choice ${
                                selectedMembers.some(
                                  (selectedMember) =>
                                    selectedMember.user_id ===
                                    departmentMember.id
                                )
                                  ? "selected"
                                  : ""
                              }`}
                              key={index}
                              onClick={() =>
                                handleSelectedMembers(departmentMember.id)
                              }
                            >
                              <div>
                                <p>
                                  {departmentMember.first_name}{" "}
                                  {departmentMember.last_name}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          No department members, please select a department to
                          display their members <br /> <br />{" "}
                          <div style={{ color: "#F87C47", fontWeight: "bold" }}>
                            {" "}
                            Note: If you select a department and there is no
                            department members, it means that the department
                            doesn't have any member at the moment
                          </div>
                        </div>
                      )}
                      {!complaint && (
                        <>
                          <div className="comment">
                            <p>Enter details of the request for this report</p>
                            <textarea
                              onChange={(e) => setComment(e.target.value)}
                              value={comment}
                              name=""
                              id=""
                              rows="4"
                              placeholder="Type your content here"
                              required
                            ></textarea>
                          </div>
                          <div className="files-list small">
                            {uploadedFiles.map((file, index) => (
                              <div className="file-container">
                                <div className="image-content">
                                  <p className="file-title">{file.name}</p>
                                  <small>
                                    {
                                      <DateFormatter
                                        dateString={file.created_at}
                                      />
                                    }
                                  </small>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="file">
                            <p>
                              Upload any documentation here that pertains to
                              this report
                            </p>
                            {uploadingDocuments ? (
                              "Uploading ..."
                            ) : (
                              <input
                                type="file"
                                onChange={handleFileChange}
                                name="files"
                                id="files"
                                multiple
                              />
                            )}
                          </div>
                        </>
                      )}
                      <div className="buttons">
                        <button
                          onClick={closeForm}
                          type="button"
                          className="outline-button"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleSendToDepartment}
                          type="button"
                          className="primary-button"
                        >
                          {isLoading ? (
                            <>
                              <Loading02Icon
                                className="loading-icon"
                                size={20}
                                variant={"stroke"}
                              />
                              Sending
                            </>
                          ) : departmentNotified ? (
                            <>
                              <CheckmarkCircle02Icon
                                size={20}
                                variant={"stroke"}
                              />
                            </>
                          ) : (
                            <>
                              <span>Send now</span>
                              <ArrowRight02Icon size={20} variant={"stroke"} />
                            </>
                          )}
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="no-content-found">
                      <h3>No head of department found</h3>
                      <p>
                        We could not find head of department for{" "}
                        {selectedDepartment.label}
                      </p>

                      {permission && permission.includes("Super User") && (
                        <div className="buttons">
                          <button
                            onClick={closeForm}
                            type="button"
                            className="tertiary-button"
                          >
                            Close
                          </button>
                          <Link
                            to={`/facilities/${incident.report_facility.id}/departments/${selectedDepartmentId}/`}
                            className="button primary-button"
                          >
                            <PencilEdit02Icon size={20} /> Edit department
                          </Link>
                        </div>
                      )}
                    </div>
                  )
                }
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SendToDepartmentForm;
