import React, { useEffect, useState } from "react";
import api from "../../../api";
import CustomSelectInput from "../../incidents/forms/inputs/customSelect";
import {
  FloppyDiskIcon,
  Loading02Icon,
  ArrowLeft02Icon,
} from "hugeicons-react";
import CustomDatePicker from "../../incidents/forms/inputs/datePicker";
import { useParams } from "react-router-dom";

const EditProfileForm = ({ setShowUpdateUserForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [address, setAddress] = useState("");
  const [birthCountry, setBirthCountry] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      setErrorMessage("");
      try {
        const response = await api.get(`/accounts/profile/`);
        const profileData = response.data;
        setProfile(profileData);
        setFirstName(profileData.first_name || "");
        setLastName(profileData.last_name || "");
        setEmail(profileData.email || "");
        setBirthCountry(profileData.profile.birth_country || "");
        setPhoneNumber(profileData.profile.phone_number || "");
        setGender(profileData.profile.gender || "");
        setDateOfBirth(profileData.profile.date_of_birth || "");
        setAddress(profileData.profile.address || "");
        console.log(response.data);
      } catch (error) {
        setErrorMessage(
          error.response?.data?.message || "Error fetching profile data"
        );
        console.log(error);
      }
    };
    fetchProfile();
  }, []);

  const handleUpdateUser = async () => {
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      gender: gender,
      date_of_birth: dateOfBirth,
      address: address,
      birth_country: birthCountry,
    };

    try {
      setIsLoading(true);
      const response = await api.patch("/accounts/profile/update/", userData);
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage("Profile updated successfully");
        setTimeout(() => {
          setSuccessMessage("");
          setIsLoading(false);
          window.location.reload();
        }, 2000);
        console.log("Profile response", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(
        error.response?.data?.message ||
          error.response?.data?.error ||
          "Error updating profile"
      );
      console.log(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      console.log(error);
    }
  };

  return (
    <div className="change-password-popup">
      <div className="change-password">
        <form className="form">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <div className="half">
            <div className="field">
              <label htmlFor="firstName">
                First name <span>*</span>
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="Enter user first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="lastName">
                Last name <span>*</span>
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Enter user last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="half">
            <div className="field">
              <label htmlFor="email">
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter user email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="phoneNumber">Phone number</label>
              <input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Enter user phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="half">
            <div className="field">
              <label htmlFor="gender">Gender</label>
              <CustomSelectInput
                options={["Male", "Female", "Other"]}
                placeholder={"Select gender"}
                selected={gender}
                setSelected={setGender}
              />
            </div>
            <div className="field">
              <label htmlFor="dateOfBirth">Date of birth | {dateOfBirth}</label>

              <CustomDatePicker
                selectedDate={dateOfBirth}
                setSelectedDate={setDateOfBirth}
              />
            </div>
          </div>
          <div className="half">
            <div className="field">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Enter user address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="birthCountry">Birth country</label>
              <input
                type="text"
                name="birthCountry"
                id="birthCountry"
                placeholder="Enter birth country"
                value={birthCountry}
                onChange={(e) => setBirthCountry(e.target.value)}
              />
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              className="secondary-button"
              onClick={() => setShowUpdateUserForm(false)}
            >
              <ArrowLeft02Icon /> Cancel
            </button>
            <button
              type="button"
              disabled={isLoading}
              className="primary-button"
              onClick={handleUpdateUser}
            >
              {isLoading ? (
                <Loading02Icon className="loading-icon" size={20} />
              ) : (
                <>
                  {" "}
                  <FloppyDiskIcon />
                  Save profile
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfileForm;
