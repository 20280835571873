import React, { useEffect, useState } from "react";
import SideBar from "../../components/dashaboard/sideBar";
import DashboardContainer from "./dashboardContainer";
import {
  useDepartments,
  usePermission,
} from "../../contexts/permissionsContext";
import {
  GoogleDocIcon,
  MedicineSyrupIcon,
  UserAccountIcon,
  DocumentAttachmentIcon,
  JobLinkIcon,
  Settings05Icon,
  Note01Icon,
  NoteEditIcon,
  ArrowRight01Icon,
  PencilEdit02Icon,
  EyeIcon,
  CheckmarkSquare01Icon,
  SquareIcon,
} from "hugeicons-react";

import "../../assets/css/pages/incidentTrackingPage/incidentTrackingPage.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import DateFormatter from "./incidents/dateFormatter";
import toast from "react-hot-toast";

const IncidentTrackingPageContent = () => {
  const permission = usePermission();
  const department = useDepartments();
  const [activeTab, setActiveTab] = useState("all");

  if (activeTab === "all" || activeTab === "drafts") {
    localStorage.removeItem("changeBreadCrumbs");
  }
  return permission.includes("Super User") ? (
    <>
      <div className="dashboard-page-content">
        <div className="tracking-headers">
          <div className="title">
            <h3>Incident reports</h3>
            <small>Detailed logs of recent incidents</small>
          </div>

          <div className="tabs">
            <div
              onClick={() => {
                setActiveTab("all");
                localStorage.removeItem("changeBreadCrumbs");
              }}
              className={`reports-tab tracking-tab ${
                activeTab === "all" ? "active" : ""
              }`}
            >
              <Note01Icon />
              <span>All reports</span>
            </div>

            <div
              onClick={() => {
                setActiveTab("drafts");
                localStorage.removeItem("changeBreadCrumbs");
              }}
              className={`drafts tracking-tab  ${
                activeTab === "drafts" ? "active" : ""
              }`}
            >
              <NoteEditIcon />
              <span>Drafts</span>
            </div>
          </div>
        </div>
        <div className="tabs-content">
          {activeTab === "all" && (
            <div className="incidents-reports">
              {permission.includes("Super User") && (
                <Link
                  to={"/incident/general/"}
                  onClick={() => {
                    localStorage.removeItem("changeBreadCrumbs");
                  }}
                  className="incident-report"
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <GoogleDocIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>General Incident Reports</h3>
                    {/* <small>Last updated on 24 June, 2023</small> */}
                  </div>
                </Link>
              )}
              {permission.includes("Super User") ? (
                <Link
                  to={"/incident/drug-reaction/"}
                  className="incident-report"
                  onClick={() => {
                    localStorage.removeItem("changeBreadCrumbs");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <MedicineSyrupIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Anaphylaxis/Adverse Drug Reaction Reports</h3>
                    {/* <small>Last updated on 24 June, 2023</small> */}
                  </div>
                </Link>
              ) : (
                ""
              )}

              {permission.includes("Super User") && (
                <Link
                  to={"/incident/employee/"}
                  onClick={() => {
                    localStorage.removeItem("changeBreadCrumbs");
                  }}
                  className="incident-report"
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <GoogleDocIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Staff Incident Reports</h3>
                    {/* <small>Last updated on 24 June, 2023</small> */}
                  </div>
                </Link>
              )}

              {permission.includes("Super User") && (
                <Link
                  to={"/incident/grievance/"}
                  onClick={() => {
                    localStorage.removeItem("changeBreadCrumbs");
                  }}
                  className="incident-report"
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <DocumentAttachmentIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Complaint & Grievance Reports</h3>
                    {/* <small>Last updated on 24 June, 2023</small> */}
                  </div>
                </Link>
              )}

              {permission.includes("Super User") && (
                <Link
                  to={"/incident/lost-and-found/"}
                  className="incident-report"
                  onClick={() => {
                    localStorage.removeItem("changeBreadCrumbs");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <Settings05Icon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Lost & Found Property Reports</h3>
                    {/* <small>Last updated on 24 June, 2023</small> */}
                  </div>
                </Link>
              )}

              {permission.includes("Super User") ? (
                <Link
                  to={"/incident/workplace-violence/"}
                  className="incident-report"
                  onClick={() => {
                    localStorage.removeItem("changeBreadCrumbs");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <JobLinkIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Workplace Violence Incident Reports</h3>
                    {/* <small>Last updated on 24 June, 2023</small> */}
                  </div>
                </Link>
              ) : (
                ""
              )}

              {permission.includes("Super User") ? (
                <Link
                  to={"/incident/medication_error/"}
                  className="incident-report"
                  onClick={() => {
                    localStorage.removeItem("changeBreadCrumbs");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <JobLinkIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Medication Error Reports</h3>
                    {/* <small>Last updated on 24 June, 2023</small> */}
                  </div>
                </Link>
              ) : (
                ""
              )}
            </div>
          )}
          {activeTab === "drafts" && (
            <div className="drafts">
              <div className="drafts">
                <DraftsTab />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="no-access-text">You don't have access to this page</div>
  );
};
const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />{" "}
      <Link className="current-page">Incidents</Link>
    </div>
  );
};
const IncidentTrackingPage = () => {
  return (
    <DashboardContainer
      content={<IncidentTrackingPageContent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

export default IncidentTrackingPage;

export const DraftsTab = () => {
  const [drafts, setDrafts] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [generalIncidents, setGeneralIncidents] = useState(
    drafts.general_incident
  );
  const [grievances, setGrievances] = useState(drafts.grievances);
  const [grievanceInvestigations, setGrievancesInvestigation] = useState([]);
  const [lostAndFoundIncidents, setLostAndFoundIncidents] = useState([]);
  const [employeeIncidents, setEmployeeIncidents] = useState([]);
  const [employeeHealthInvestigations, setEmployeeHealthInvestigations] =
    useState([]);
  const [workplaceViolenceIncidents, setWorkplaceViolenceIncidents] = useState(
    []
  );
  const [adverseDrugReaction, setAdverseDrugReaction] = useState([]);
  const [medicationError, setMedicationError] = useState([]);
  useEffect(() => {
    // Fetch drafts data

    const fetchDrafts = async () => {
      // API call to fetch drafts data
      try {
        const response = await api.get(`incidents/overview/draft/user/`);
        if (response.status === 200) {
          setDrafts(response.data);
          setGrievancesInvestigation(response.data.grievance_investigation);
          setGrievances(response.data.grievance_incident);
          setGeneralIncidents(response.data.general_incident);
          setLostAndFoundIncidents(response.data.lost_and_found);
          setEmployeeIncidents(response.data.employee_incident);
          setEmployeeHealthInvestigations(response.data.health_investigation);
          setWorkplaceViolenceIncidents(response.data.workplace_violence);
          setAdverseDrugReaction(response.data.adverse_drug_reaction);
          setMedicationError(response.data.medical_error);
          console.log(response.data);
          setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          setError(
            error.response.data.message ||
              error.response.error ||
              "Error fetching drafts data, try again later"
          );
        } else {
          setError("Unknown fetching drafts data, try again later");
        }
        setLoading(false);
        console.error(error);
      }
    };

    fetchDrafts();
  }, []);

  return loading ? (
    "Loading"
  ) : (
    <div className="draft-list">
      {error && <div className="error-message">{error}</div>}
      {drafts && drafts.length > 0 ? (
        "No drafts found"
      ) : (
        <div className="drafts-categories">
          Drafts (
          {Object.values(drafts).reduce(
            (acc, currArray) => acc + currArray.length,
            0
          )}
          )
          {generalIncidents && generalIncidents.length > 0 ? (
            <DraftCategory
              incident={generalIncidents}
              title={"General incident reports"}
              apiLink={"incident/general"}
              incidentName={"general"}
            />
          ) : (
            ""
          )}
          {grievances && grievances.length > 0 ? (
            <DraftCategory
              incident={grievances}
              title={"Grievance reports"}
              apiLink={"incident/grievance"}
              incidentName={"grievance"}
            />
          ) : (
            ""
          )}
          {adverseDrugReaction && adverseDrugReaction.length > 0 ? (
            <DraftCategory
              incident={adverseDrugReaction}
              title={"Adverse drug reaction reports"}
              apiLink={"incident/drug-reaction"}
              incidentName={"adverse_drug_reaction"}
            />
          ) : (
            ""
          )}
          {lostAndFoundIncidents && lostAndFoundIncidents.length > 0 ? (
            <DraftCategory
              incident={lostAndFoundIncidents}
              title={"Lost & Found property reports"}
              apiLink={"incident/lost_and_found"}
              incidentName={"lost_and_found"}
            />
          ) : (
            ""
          )}
          {workplaceViolenceIncidents &&
          workplaceViolenceIncidents.length > 0 ? (
            <DraftCategory
              incident={workplaceViolenceIncidents}
              title={"Workplace violence reports"}
              apiLink={"incident/workplace_violence"}
              incidentName={"workplace_violence"}
            />
          ) : (
            ""
          )}
          {medicationError && medicationError.length > 0 ? (
            <DraftCategory
              incident={medicationError}
              title={"Medication error reports"}
              apiLink={"incident/medication_error"}
              incidentName={"medication_error"}
            />
          ) : (
            ""
          )}
          {employeeIncidents && employeeIncidents.length > 0 ? (
            <DraftCategory
              incident={employeeIncidents}
              title={"Staff Incident reports"}
              apiLink={"incident/employee_incident"}
              incidentName={"employee_incident"}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export const DraftCategory = ({ incident, title, apiLink, incidentName }) => {
  console.log(incident);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const handleSelectedItems = (item) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      );
    }
  };

  const handleRowClick = (incidentId) => {
    navigate(`/${apiLink}/${incidentId}`);
    localStorage.setItem("canViewDraft", true);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/${apiLink}/${incidentId}/modify/`);
    localStorage.setItem("canModifyDraft", true);
  };

  const handleNonClickableColumnClick = (event) => {
    localStorage.setItem("canViewDraft", true);
    event.stopPropagation();
  };

  const handleSelectAll = (items) => {
    if (selectedItems !== items) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  console.log(selectedItems);
  const handleDeleteDraft = async () => {
    if (selectedItems.length > 0) {
      const userId = localStorage.getItem("userId");
      console.log({
        incident_ids: selectedItems.map((item) => item.id),
        user_id: parseInt(userId),
      });
      try {
        setIsLoading(true);
        const response = await api.delete(
          `/incidents/${incidentName}/drafts/delete-multiple/`,
          {
            data: {
              incident_ids: selectedItems.map((item) => item.id),
              user_id: parseInt(userId),
            },
          }
        );

        if (
          response.status === 204 ||
          response.status === 201 ||
          response.status === 200
        ) {
          setIsLoading(false);
          window.customToast.success("Draft deleted successfully");
          setSelectedItems([]);
          setTimeout(() => {
            window.location.reload();
          }, 1000);

          // setGeneralIncidents([]);
        } else {
          console.error(response.data);
          setSelectedItems([]);
        }
      } catch (error) {
        window.customToast.error(error.message);
        console.error(error);
        setIsLoading(false);
        setSelectedItems([]);
      }
    } else {
      alert("No drafts selected");
      setSelectedItems([]);
    }
  };

  return (
    <div className="categories">
      <div className="row">
        <div className="categories-title">
          <NoteEditIcon />
          <h3>
            {title} ({incident.length})
          </h3>
        </div>
        <button
          className={isLoading ? "processing" : "delete-draft"}
          onClick={handleDeleteDraft}
        >
          {isLoading ? "Processing..." : "Delete draft"}
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th>
              <div onClick={() => handleSelectAll(incident)}>
                {" "}
                {selectedItems === incident ? (
                  <CheckmarkSquare01Icon />
                ) : (
                  <SquareIcon />
                )}
              </div>
            </th>
            <th>No</th>
            <th>ID</th>
            <th>Current step</th>
            <th>Created at</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {incident &&
            incident.map((draft, index) => (
              <tr
                key={index}
                className={`table-card ${
                  selectedItems.includes(draft) ? "selected" : ""
                }`}
              >
                <td>
                  <div
                    onClick={() => handleSelectedItems(draft)}
                    className="icon"
                  >
                    {selectedItems.includes(draft) ? (
                      <CheckmarkSquare01Icon color="orange" />
                    ) : (
                      <SquareIcon />
                    )}
                  </div>
                </td>
                <td>{index + 1}</td>
                <td>{draft.id}</td>
                <td>{draft.current_step}</td>

                <td>
                  <DateFormatter dateString={draft.created_at} />
                </td>
                <td>
                  {" "}
                  <p
                    className={`follow-up ${
                      draft.status === "Draft"
                        ? "in-progress"
                        : incident.status === "Closed"
                        ? "closed"
                        : "Open"
                    }`}
                  >
                    {incident.status || "Not specified"}
                  </p>
                </td>
                <td
                  onClick={(event) => handleNonClickableColumnClick(event)}
                  className="action-col"
                >
                  <div className="table-actions">
                    {!incident.is_resolved && (
                      <PencilEdit02Icon
                        size={20}
                        onClick={() => navigateToModify(draft.id)}
                      />
                    )}
                    <EyeIcon
                      size={20}
                      onClick={() => handleRowClick(draft.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
