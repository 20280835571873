import React, { useState, useEffect } from "react";
import RichTexField from "./forms/inputs/richTexField";
import CustomDatePicker from "./forms/inputs/datePicker";
import api, { API_URL, cleanedData } from "../../api";
import { FloppyDiskIcon, Loading02Icon } from "hugeicons-react";

const LostAndFoundInvestigation = ({ lostFoundId }) => {
  const [dateLetterWasSent, setDateLetterWasSent] = useState("");
  const [letterSentToWho, setLetterSentToWho] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [unclaimedPropertyDisposal, setUnclaimedPropertyDisposal] =
    useState("");
  const [nameOfPersonReportingLoss, setNameOfPersonReportingLoss] =
    useState("");

  async function patchData() {
    let formData;

    try {
      setIsLoading(true);
      const response = await api.patch(
        `${API_URL}/incidents/lost_and_found/${lostFoundId}/update/`,

        cleanedData({
          disposal_of_unclaimed_property: unclaimedPropertyDisposal,
          status: "Open",
          date_letter_sent: dateLetterWasSent,
          letter_sent_to: letterSentToWho,
        })
      );

      if (response.status === 200 || response.status === 201) {
        window.customToast.success("Data posted successfully");

        setIsLoading(false);
      }

      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      window.customToast.error("Failed to post data");
      window.customToast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  // if (isValid) {

  // }

  return (
    <div className="step">
      <div className="field name">
        <label htmlFor="unclaimedPropertyDisposal">
          Disposal of Unclaimed Property
        </label>
        <RichTexField
          value={unclaimedPropertyDisposal}
          onEditorChange={setUnclaimedPropertyDisposal}
        />
      </div>
      <div className="half">
        <div className="field">
          <label htmlFor="dateReturned">Date letter was sent</label>
          <CustomDatePicker
            selectedDate={dateLetterWasSent}
            setSelectedDate={setDateLetterWasSent}
          />
        </div>

        <div className="field">
          <label htmlFor="letterSentTo">Letter was sent to</label>
          <input
            onChange={(e) => setLetterSentToWho(e.target.value)}
            value={letterSentToWho}
            type="text"
            name="letterSentTo"
            id="letterSentTo"
            placeholder="Enter person"
          />
        </div>
        <button className="primary-button" onClick={patchData}>
          {isLoading ? (
            <>
              <Loading02Icon className="loading-icon" size={18} />
              <span>Saving changes</span>
            </>
          ) : (
            <>
              <FloppyDiskIcon size={20} />
              <span>Save and submit</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default LostAndFoundInvestigation;
