import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import api, { API_URL } from "../../../api";

import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";

import DrugReactionContentTab from "./drugReactionIncident/DrugReactionContentTab";
import DrugReactionGeneraInfo from "./drugReactionIncident/DrugReactionGeneraInfo";
import DrugReactionOtherInformation from "./drugReactionIncident/DrugReactionOtherInformation";
import DrugReactionDocumentHistory from "./drugReactionIncident/DrugReactionDocumentHistory";
import toast from "react-hot-toast";
import DrugReactionReviews from "./drugReactionIncident/DrugReactionReviews";
import FilesList from "../../../components/documents/filesList";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import NoResources from "../../../components/general/noResources";

const BreadCrumbs = () => {
  const { drugReactionId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/drug-reaction/"}>
        Anaphylaxis/Adverse Drug Reaction List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{drugReactionId}</Link>
    </div>
  );
};

export const FacilityDetailsBreadCrumbs = ({ incidentID }) => {
  const [facilityName, setFacilityName] = useState("");
  const [facilityId, setFacilityId] = useState(null);

  useEffect(() => {
    setFacilityName(localStorage.getItem("facilityName"));
    setFacilityId(localStorage.getItem("facilityId"));
  });
  return (
    <div className="breadcrumbs">
      <Link to={"/"} replace>
        Overview
      </Link>
      <ArrowRight01Icon />
      <Link to={"/facilities/"} replace>
        Facilities
      </Link>
      <ArrowRight01Icon />
      <Link to={`/facilities/${facilityId}/`} replace>
        {facilityName}
      </Link>
      <ArrowRight01Icon />
      <Link className="current-page" replace>
        #{incidentID}
      </Link>{" "}
    </div>
  );
};

function DrugReactionDetailsContent() {
  const { drugReactionId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [latestIncidentDetails, setLatestIncidentDetails] = useState({});
  const [useOriginalVersion, setUseOriginalVersion] = useState(true);
  const [currentIncidentData, setCurrentIncidentData] = useState({});
  const [hasAccess, setHasAccess] = useState(true);
  //   const [incidentStatus, setIncidentStatus] = useState({});

  const fetchIncidentDetails = async () => {
    setIsFetching(true);
    try {
      let response;

      // Fetch the original version of the incident
      if (useOriginalVersion) {
        response = await api.get(
          `${API_URL}/incidents/adverse_drug_reaction/${drugReactionId}/`
        );
        setIncidentDetails(response.data.data); // Store the original data
        setCurrentIncidentData(response.data.data); // Set current data for UI
        console.log(response.data);
      } else {
        // Fetch the latest modified version of the incident
        const res = await api.get(
          `${API_URL}/incidents/adverse_drug_reaction/${drugReactionId}/`
        );
        const latestIncident = res.data.modifications.versions.find((mod) => {
          return mod.latest === true;
        });
        if (res.status === 403) {
          setHasAccess(false);
        }

        if (latestIncident) {
          response = await api.get(
            `${API_URL}/incidents/adverse_drug-reaction/${drugReactionId}/versions/${latestIncident.id}/`
          );
          console.log(response.data);
          console.log(latestIncident);
          if (response.status === 403) {
            setHasAccess(false);
          }
        } else {
          response = res;
        }

        setLatestIncidentDetails(response.data); // Store the latest modified version
        setCurrentIncidentData(response.data); // Set current data for UI
      }

      setIsFetching(false); // Stop loading state
    } catch (error) {
      console.error("Error fetching incident details:", error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchIncidentDetails();
    console.log("currentincidentdata: ", currentIncidentData); // Fetch incident data when version toggles or incidentId changes
  }, [drugReactionId, useOriginalVersion]);

  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/adverse_drug_reaction/${drugReactionId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${drugReactionId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return hasAccess ? (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : incidentDetails && Object.keys(incidentDetails).length > 0 ? (
        <div className="incident-details">
          {incidentDetails.modifications ? (
            <IncidentDetailsHeader
              data={
                useOriginalVersion ? incidentDetails : latestIncidentDetails
              }
              incidentDetailsId={drugReactionId}
              apiLink={"adverse_drug_reaction"}
              sendTo={"send-to-department"}
              managerAccess={false}
              useOriginalVersion={useOriginalVersion}
              setCurrentIncidentData={setCurrentIncidentData}
              showClosedManager={false}
            />
          ) : (
            ""
          )}

          <div className="details">
            <IncidentDetails
              data={currentIncidentData}
              fullName={`${currentIncidentData.patient_name?.user?.last_name} ${currentIncidentData.patient_name?.user?.first_name} `}
              sex={currentIncidentData.patient_name?.gender}
              IncidentDate={currentIncidentData.incident_date}
              incidentTime={currentIncidentData.incident_time}
              incidentCategory={currentIncidentData.patient_type}
              incidentDetails={
                <DrugReactionContentTab data={currentIncidentData} />
              }
            />
            <IncidentTabs
              data={currentIncidentData}
              //   statuses={incidentStatus}
              generalInformation={
                <DrugReactionGeneraInfo
                  data={currentIncidentData}
                  //   incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <DrugReactionOtherInformation data={currentIncidentData} />
              }
              documentHistory={
                <DrugReactionDocumentHistory incidentId={drugReactionId} />
              }
              reviews={<DrugReactionReviews incidentId={drugReactionId} />}
              documents={<IncidentDocuments incidentId={drugReactionId} />} //Add a form to upload a document on this incident
            />
          </div>
        </div>
      ) : (
        <NoResources />
      )}
    </div>
  ) : (
    <div className="no-access-text">You don't have access to this page</div>
  );
}
const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/adverse_drug_reaction/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log("Drug reaction: ", response.data);

          localStorage.setItem("incidentDocumentCount", response.data.length);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};
function DrugReactionDetails() {
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  const { drugReactionId } = useParams();
  return (
    <div>
      <DashBoardContainer
        content={<DrugReactionDetailsContent />}
        breadCrumbs={
          changeBreadCrumbs ? (
            <FacilityDetailsBreadCrumbs incidentID={drugReactionId} />
          ) : (
            <BreadCrumbs />
          )
        }
      />
    </div>
  );
}

export default DrugReactionDetails;
