import React, { useState, useRef, useEffect } from "react";
import DashBoardContainer from "../../dashboardContainer";
import CustomDatePicker from "../../../../components/incidents/forms/inputs/datePicker";
import CustomSelectInput from "../../../../components/incidents/forms/inputs/customSelect";
import {
  AddCircleIcon,
  CheckmarkSquare01Icon,
  FloppyDiskIcon,
  Loading02Icon,
  RemoveCircleIcon,
  SquareIcon,
} from "hugeicons-react";
import RichTexField from "../../../../components/incidents/forms/inputs/richTexField";
import { incident_agreement, outComeData } from "../../../../constants";
import BackToPage from "../../../../components/incidents/backToPage";
import api from "../../../../api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ModifyAdverseDruReactionForm from "../../../../components/incidents/forms/modiy/adverseDruReaction";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { usePermission } from "../../../../contexts/permissionsContext";
import NoResources from "../../../../components/general/noResources";

export const FacilityBreadCrumbs = ({ facilityIncidentLink }) => {
  const [facilityName, setFacilityName] = useState("");
  const [facilityId, setFacilityId] = useState(null);
  const { incidentId } = useParams();
  useEffect(() => {
    setFacilityName(localStorage.getItem("facilityName"));
    setFacilityId(localStorage.getItem("facilityId"));
  });
  return (
    <div className="breadcrumbs">
      <Link to={"/"} replace>
        Overview
      </Link>
      <ArrowRight01Icon />
      <Link to={"/facilities/"} replace>
        Facilities
      </Link>
      <ArrowRight01Icon />
      <Link to={`/facilities/${facilityId}/`} replace>
        {facilityName}
      </Link>
      <ArrowRight01Icon />
      <Link to={`/incident/${facilityIncidentLink}/${incidentId}/`} replace>
        #{incidentId}
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};

const ModifyMedicalAdverseDrugReactionIncidentPageContent = () => {
  const [incident, setIncident] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { incidentId } = useParams();
  const [hasAccess, setHasAccess] = useState(true);
  const permission = usePermission();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchIncident = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(
          `/incidents/adverse_drug_reaction/${incidentId}/`
        );
        if (response.status === 200) {
          console.log("Data: ", response.data.data.incident);
          setIncident(response.data.data.incident);
          setIsLoading(false);
        }
        if (response.status === 403) {
          setHasAccess(false);
        }
      } catch (error) {
        if (error.response.status === 404) {
          setIsError(true);
        }
        console.log(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchIncident();
  }, []);
  return isLoading ? (
    "Getting data..."
  ) : incident && !isError ? (
    <ModifyAdverseDruReactionForm
      hasAccess={
        permission.includes("Super User") ||
        permission.includes("Admin") ||
        permission.includes("Manager")
      }
      data={incident}
    />
  ) : (
    <NoResources />
  );
};

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/drug-reaction/"}>
        Anaphylaxis/Adverse Drug Reaction List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/drug-reaction/${incidentId}/`}>
        #{incidentId}
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};

const ModifyMedicalAdverseDrugReactionIncidentPage = () => {
  const [incident, setIncident] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { incidentId } = useParams();
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");

  useEffect(() => {
    const fetchIncident = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(
          `/incidents/adverse_drug_reaction/${incidentId}/`
        );
        if (response.status === 200) {
          console.log("Data: ", response.data.data);
          setIncident(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchIncident();
  }, []);
  return isLoading ? (
    "..."
  ) : (
    <DashBoardContainer
      content={
        <ModifyMedicalAdverseDrugReactionIncidentPageContent data={incident} />
      }
      breadCrumbs={
        changeBreadCrumbs ? (
          <FacilityBreadCrumbs facilityIncidentLink={"drug-reaction"} />
        ) : (
          <BreadCrumbs />
        )
      }
    />
  );
};

export default ModifyMedicalAdverseDrugReactionIncidentPage;
